import { useCallback } from "react";

import { SearchableAsyncSelect } from "src/components";

import { protocolFillerApi } from "../api";
import { useProtocolFillerContext } from "../contexts";

interface Props {
  questionId: string;
  linkTypeId: string;
}

export function LinkingQuestionComponent({ questionId, linkTypeId }: Props) {
  const { responses, setLinkingResponse, vehicleId } =
    useProtocolFillerContext();

  const { linkingResponses } = responses;

  const setResponse = useCallback(
    (response) => {
      setLinkingResponse(questionId, response);
    },
    [setLinkingResponse, questionId]
  );

  const searchVehicles = useCallback(
    async (query: string) => {
      if (!vehicleId || !linkTypeId)
        throw new Error(
          "vehicleId and linkTypeId must be defined to search vehicles"
        );
      return await protocolFillerApi.getVehiclesByLinkTypeAndVehicle(
        linkTypeId,
        vehicleId,
        query
      );
    },
    [vehicleId, linkTypeId]
  );

  return (
    <SearchableAsyncSelect<{ value: string; label: string }>
      searchElements={searchVehicles}
      onChange={setResponse}
      value={linkingResponses[questionId]}
      valueKey="value"
      getLabel={useCallback((el) => el.label, [])}
    />
  );
}
