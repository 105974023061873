import dayjs from "dayjs";

import { api, Api } from "src/utils";

export interface DateFromToParams {
  dateFrom: string;
  dateTo: string;
}

export interface VehicleTypeParams {
  vehicleType: null | string;
}

export interface CustomVehicleFieldsParams {
  customVehicleFields: string[];
}

export interface IncludeInitialDeficienciesParams {
  includeInitialDeficiencies: boolean;
  doNotIncludeFieldsCurrentlyOk: boolean;
}

export interface ProtocolTypesParams {
  protocolTypes: string[];
}

export interface VehicleGroupsParams {
  vehicleGroups: string[];
}

export interface ShowOnlyChangesTypeParams {
  showOnlyChanges: boolean;
}

export class ReportsApi {
  constructor(private api: Api) {}

  async startDownloadProtocolsPerUser(
    params: DateFromToParams & ProtocolTypesParams
  ): Promise<{ taskResultId: string }> {
    return this.api.post(
      "/backoffice/reports/number-of-protocols-per-user/",
      params
    );
  }
  async startDownloadNumberOfProtocolsPerVehicle(
    params: DateFromToParams &
      VehicleTypeParams &
      ProtocolTypesParams &
      VehicleGroupsParams
  ): Promise<{ taskResultId: string }> {
    return this.api.post(
      "/backoffice/reports/number-of-protocols-per-vehicle/",
      params
    );
  }

  async startDownloadNumberOfOkAndNotOkProtocols(
    params: DateFromToParams
  ): Promise<{ taskResultId: string }> {
    return this.api.post(
      "/backoffice/reports/number-of-ok-and-not-ok-protocols/",
      params
    );
  }

  async startDownloadNumberOfNotOkVehicleFields(
    params: DateFromToParams &
      CustomVehicleFieldsParams &
      VehicleTypeParams &
      IncludeInitialDeficienciesParams
  ): Promise<{ taskResultId: string }> {
    return this.api.post(
      "/backoffice/reports/number-of-not-ok-vehicle-fields/",
      params
    );
  }

  async startDownloadVehicleStatesByDate(
    params: DateFromToParams &
      CustomVehicleFieldsParams &
      VehicleTypeParams &
      ShowOnlyChangesTypeParams
  ): Promise<{ taskResultId: string }> {
    const timezoneName = dayjs.tz.guess();
    return this.api.post("/backoffice/reports/vehicle-states-by-date/", {
      ...params,
      timezoneName,
    });
  }

  async startDownloadListOfReportedDeficiencies(
    params: DateFromToParams &
      CustomVehicleFieldsParams &
      VehicleTypeParams &
      IncludeInitialDeficienciesParams
  ): Promise<{ taskResultId: string }> {
    const timezoneName = dayjs.tz.guess();
    return this.api.post("/backoffice/reports/list-of-reported-deficiencies/", {
      ...params,
      timezoneName,
    });
  }

  async startDownloadProtocolsCreationLocation(
    params: DateFromToParams
  ): Promise<{ taskResultId: string }> {
    return this.api.post(
      "/backoffice/reports/protocols-creation-location/",
      params
    );
  }
}

export const reportsApi = new ReportsApi(api);
