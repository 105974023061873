import produce from "immer";
import { isEmpty } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { urls } from "src/urls";
import {
  convertFileToBase64,
  disableReloadPageConfirmation,
  enableReloadPageConfirmation,
} from "src/utils";

import { FillSignature, protocolFillerApi } from "../api";
import { ErrorBoundary } from "../components";
import { Responses, Signature, Signatures, VehicleType } from "../models";
import { Position } from "../utils/position";
import { protocolFillerLocalStorageContext } from "./";
import { useProtocolFillerSummaryPageManager } from "./ProtocolFillerSummaryManagerContext";

type SendingStatus = "toSend" | "sending" | "failed" | "success";

export interface ProtocolFillerContext {
  sendingStatus: SendingStatus;
  resetSendingState: () => void;
  responses: Responses;
  signatures: Record<string, Signature>;
  hasBeenTouched: boolean;
  wasAtLeastOnePageVisited: boolean;
  setWasAtLeastOnePageVisited: (value: boolean) => void;

  createProtocol(
    templateId: string,
    vehicleId: string,
    position: Position | null
  ): Promise<string>;

  setTextResponse(
    questionId: string,
    response: Responses["textResponses"]["T"]
  ): void;

  setNumberResponse(
    questionId: string,
    response: Responses["numberResponses"]["T"]
  ): void;

  setDateResponse(
    questionId: string,
    response: Responses["dateResponses"]["T"]
  ): void;

  setDateMonthResponse(
    questionId: string,
    response: Responses["dateMonthResponses"]["T"]
  ): void;

  setChoiceResponse(
    questionId: string,
    response: Responses["choiceResponses"]["T"]
  ): void;

  setPhotoActionResponse(
    questionId: string,
    response: Responses["photoActionResponses"]["T"]
  ): Promise<void>;

  setDamagesResponse(
    questionId: string,
    response: Responses["damagesResponses"]["T"]
  ): void;

  setCustomFieldVerificationResponse(
    questionId: string,
    response: Responses["customFieldVerificationResponses"]["T"]
  ): void;

  setLinkingResponse(
    questionId: string,
    response: Responses["linkingResponses"]["T"]
  ): void;

  setSignature(signaturePageId: string, signature: Partial<Signature>): void;

  setProtocolVehicleId(vehicleId: string): void;
  vehicleId: string | null;
}

export const emptyResponses: Responses = {
  textResponses: {},
  numberResponses: {},
  dateResponses: {},
  dateMonthResponses: {},
  choiceResponses: {},
  photoActionResponses: {},
  damagesResponses: {},
  customFieldVerificationResponses: {},
  linkingResponses: {},
};

export const protocolFillerContextInitialValue: ProtocolFillerContext = {
  responses: emptyResponses,
  sendingStatus: "toSend",
  resetSendingState: () => null,
  signatures: {},
  hasBeenTouched: false,
  wasAtLeastOnePageVisited: false,
  setWasAtLeastOnePageVisited: () => null,
  setTextResponse: () => {},
  setNumberResponse: () => {},
  setDateResponse: () => {},
  setDateMonthResponse: () => {},
  setChoiceResponse: () => null,
  setPhotoActionResponse: () => Promise.resolve(),
  setDamagesResponse: () => null,
  setCustomFieldVerificationResponse: () => null,
  setLinkingResponse: () => null,
  setSignature: () => {},
  createProtocol: async () => "",
  vehicleId: null,
  setProtocolVehicleId: () => null,
};

export const protocolFillerContext = createContext<ProtocolFillerContext>(
  protocolFillerContextInitialValue
);

interface Props {
  children: JSX.Element;
  vehicleTypes: VehicleType[];
}

export function ProtocolFillerContextProvider(props: Props) {
  const { setProtocolSuccessfullySentScreenState } =
    useProtocolFillerSummaryPageManager();
  const { t } = useTranslation("protocolFiller");
  const { t: tGeneric } = useTranslation();
  const { Provider } = protocolFillerContext;
  const {
    responses: initialResponses,
    signatures: initialSignatures,
    persistResponses,
    persistSignatures,
    clearStorage,
  } = useContext(protocolFillerLocalStorageContext);

  const history = useHistory();

  const [signatures, setSignatures] = useState<Signatures>(initialSignatures);
  const [responses, setResponses] = useState<Responses>(initialResponses);

  const [wasAtLeastOnePageVisited, setWasAtLeastOnePageVisited] =
    useState(false);

  const [hasBeenTouched, setHasBeenTouched] = useState(
    isAnyResponseFilledOut(initialResponses) || !isEmpty(initialSignatures)
  );
  const [sendingStatus, setSendingStatus] = useState<SendingStatus>("toSend");
  const [vehicleId, setVehicleId] = useState<string | null>(null);

  const resetSendingState = useCallback(() => {
    setSendingStatus("toSend");
  }, []);

  useEffect(() => {
    persistResponses(responses);
  }, [persistResponses, responses]);

  useEffect(() => {
    persistSignatures(signatures);
  }, [persistSignatures, signatures]);

  useEffect(() => {
    if (hasBeenTouched) {
      enableReloadPageConfirmation();
    } else {
      disableReloadPageConfirmation();
    }

    return () => {
      disableReloadPageConfirmation();
    };
  }, [hasBeenTouched]);

  const genericSetResponses = useCallback(
    <TResponsesKey extends keyof Responses>(
      responsesKey: TResponsesKey,
      questionId: string,
      response: Responses[TResponsesKey]["T"]
    ) => {
      setResponses((responses) =>
        produce(responses, (draft) => {
          draft[responsesKey][questionId] = response;
        })
      );
      setHasBeenTouched(true);
    },
    []
  );

  const setTextResponse: ProtocolFillerContext["setTextResponse"] = useCallback(
    (questionId, response) => {
      genericSetResponses("textResponses", questionId, response);
    },
    [genericSetResponses]
  );

  const setNumberResponse: ProtocolFillerContext["setNumberResponse"] =
    useCallback(
      (questionId, response) => {
        genericSetResponses("numberResponses", questionId, response);
      },
      [genericSetResponses]
    );

  const setDateResponse: ProtocolFillerContext["setDateResponse"] = useCallback(
    (questionId, response) => {
      genericSetResponses(
        "dateResponses",
        questionId,
        response === "" ? null : response
      );
    },
    [genericSetResponses]
  );

  const setDateMonthResponse: ProtocolFillerContext["setDateMonthResponse"] =
    useCallback(
      (questionId, response) => {
        genericSetResponses(
          "dateMonthResponses",
          questionId,
          response === "" ? null : response
        );
      },
      [genericSetResponses]
    );

  const setChoiceResponse: ProtocolFillerContext["setChoiceResponse"] =
    useCallback(
      (questionId, response) => {
        genericSetResponses("choiceResponses", questionId, response);
      },
      [genericSetResponses]
    );

  const setPhotoActionResponse: ProtocolFillerContext["setPhotoActionResponse"] =
    useCallback(
      async (questionId, response) => {
        genericSetResponses("photoActionResponses", questionId, response);
      },
      [genericSetResponses]
    );

  const setDamagesResponse: ProtocolFillerContext["setDamagesResponse"] =
    useCallback(
      (questionId, damages) => {
        genericSetResponses("damagesResponses", questionId, damages);
      },
      [genericSetResponses]
    );

  const setLinkingResponse: ProtocolFillerContext["setLinkingResponse"] =
    useCallback(
      (questionId, link) => {
        genericSetResponses("linkingResponses", questionId, link);
      },
      [genericSetResponses]
    );

  const setCustomFieldVerificationResponse: ProtocolFillerContext["setCustomFieldVerificationResponse"] =
    useCallback(
      (questionId, response) => {
        genericSetResponses(
          "customFieldVerificationResponses",
          questionId,
          response
        );
      },
      [genericSetResponses]
    );

  const setSignature = useCallback(
    (signaturePageId, partialSignature: Partial<Signature>) => {
      setSignatures((signatures) =>
        produce(signatures, (draft) => {
          draft[signaturePageId] = {
            ...(signatures[signaturePageId] || {
              signature: null,
              signer: null,
            }),
            ...partialSignature,
          };
        })
      );
      setHasBeenTouched(true);
    },
    []
  );

  const createProtocol = useCallback(
    async (
      templateId: string,
      vehicleId: string,
      position: Position | null
    ): Promise<string> => {
      const convertSignatures = async () => {
        const signatureEntries = Object.entries(signatures);

        const mappedSignatureEntries = await Promise.all(
          signatureEntries.map<Promise<[string, FillSignature]>>(
            async ([key, value]) => {
              const signatureSignature: FillSignature["signature"] =
                value.signature
                  ? ((await convertFileToBase64(value.signature)) as string)
                  : "";
              return [
                key,
                {
                  signature: signatureSignature,
                  signer: value.signer?.value || null,
                },
              ];
            }
          )
        );

        return Object.fromEntries(mappedSignatureEntries);
      };

      const [convertedSignatures] = await Promise.all([convertSignatures()]);

      const filteredDamagesResponses = Object.entries(
        responses.damagesResponses
      ).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === "NO_NEW_DAMAGES_CONFIRMED" ? [] : value,
        }),
        {}
      );

      try {
        setSendingStatus("sending");
        const fillTemplateResponse = await protocolFillerApi.fillTemplate(
          templateId,
          vehicleId,
          responses.textResponses,
          responses.numberResponses,
          responses.dateResponses,
          responses.dateMonthResponses,
          responses.choiceResponses,
          responses.photoActionResponses,
          filteredDamagesResponses,
          responses.linkingResponses,
          convertedSignatures,
          position
        );
        setSendingStatus("success");
        setProtocolSuccessfullySentScreenState({
          vehicleTypes: props.vehicleTypes,
          title: t("protocolCreatedSuccessfully"),
          logoutButtonText: t("logout"),
          orDividerText: tGeneric("or"),
          chooseVehicleViaQRCodeButtonLabel: t("qrCodes.chooseVehicle"),
          youCanLogOutMessage: t("youCanLogOutMessage"),
          youHaveBeenAutomaticallyLoggedOut: t(
            "youHaveBeenAutomaticallyLoggedOut"
          ),
        });
        return fillTemplateResponse;
      } catch (e) {
        setSendingStatus("failed");
        throw e;
      }
    },
    [
      responses.damagesResponses,
      responses.textResponses,
      responses.numberResponses,
      responses.dateResponses,
      responses.dateMonthResponses,
      responses.choiceResponses,
      responses.photoActionResponses,
      responses.linkingResponses,
      signatures,
      setProtocolSuccessfullySentScreenState,
      props.vehicleTypes,
      t,
      tGeneric,
    ]
  );

  return (
    <Provider
      value={{
        responses,
        sendingStatus,
        resetSendingState,
        signatures,
        hasBeenTouched,
        wasAtLeastOnePageVisited,
        setWasAtLeastOnePageVisited,
        setTextResponse,
        setNumberResponse,
        setDateResponse,
        setDateMonthResponse,
        setChoiceResponse,
        setPhotoActionResponse,
        setDamagesResponse,
        setLinkingResponse,
        setCustomFieldVerificationResponse,
        setSignature,
        createProtocol,
        vehicleId,
        setProtocolVehicleId: setVehicleId,
      }}
    >
      <ErrorBoundary
        t={t}
        onShow={clearStorage}
        onReloadClick={() => history.push(urls.landingPage())}
      >
        {props.children}
      </ErrorBoundary>
    </Provider>
  );

  function isAnyResponseFilledOut(responses: Responses): boolean {
    return !!Object.values(responses).filter((response) => !isEmpty(response))
      .length;
  }
}

export function useProtocolFillerContext() {
  return useContext(protocolFillerContext);
}
