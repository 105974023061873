import {
  NotNoneCustomVehicleFieldValueStatus,
  RegularQuestion,
  SignatureType,
} from "src/backOffice/models";
import { CustomVehicleField } from "src/company/models";
import { CommonChoiceProperties } from "src/components";

export interface TemplatePage {
  id: string;
  questions: TemplateQuestion[];
  title: string;
}

export interface TemplateSignaturePage {
  id: string;
  order: number;
  label: string;
  type: SignatureType;
  isRequired: boolean;
  signerRoles: string[];
}

export interface Template {
  id: string;
  isActive: boolean;
  label: string;
  pages: TemplatePage[];
  signaturePages: TemplateSignaturePage[];
  imageQuality: "highQuality" | "standard";
  vehicleType: string[];
  vehicleGroups: string[];
  templateTags: { id: string; label: string; order: number }[];
  allowAttachingPhotosFromGallery: boolean;
}

export interface QuestionCommon {
  id: string;
  label: string;
  description: string;
  descriptionImage: string | null;
}

interface RequiredModelQuestion {
  isRequired: boolean;
}

export interface TextQuestion extends QuestionCommon, RequiredModelQuestion {
  questionType: "text";
}

export interface DamagesQuestion extends QuestionCommon, RequiredModelQuestion {
  questionType: "damages";
}

export interface PhotoActionQuestion
  extends QuestionCommon,
    RequiredModelQuestion {
  questionType: "photo-action";
}

export interface RetrieveChoice extends CommonChoiceProperties {
  setsStatus: NotNoneCustomVehicleFieldValueStatus;
  id: string;
  label: string;
}

interface RetrieveMultiChoiceChoice extends CommonChoiceProperties {
  isPositiveOrRequired: boolean;
  id: string;
  label: string;
}

export interface ChoiceQuestion extends QuestionCommon {
  questionType: "choice";
  choices: RetrieveChoice[];
}

export interface MultiChoiceQuestion extends QuestionCommon {
  questionType: "multi-choice";
  choices: RetrieveMultiChoiceChoice[];
}

export interface NumberQuestion extends QuestionCommon, RequiredModelQuestion {
  questionType: "number";
}

export interface DateQuestion extends QuestionCommon, RequiredModelQuestion {
  questionType: "date";
}

export interface DateMonthQuestion
  extends QuestionCommon,
    RequiredModelQuestion {
  questionType: "date-month";
}

export interface CustomFieldUpdateQuestion
  extends QuestionCommon,
    RequiredModelQuestion {
  questionType: "custom-field-update";
  customVehicleField: CustomVehicleField & {
    valueFormatted: string;
    id: string;
  };
  isCustomVehicleFieldVerification: boolean;
}

export interface LinkingQuestion extends QuestionCommon, RequiredModelQuestion {
  questionType: "linking";
  linkType: string;
}

export type TemplateQuestion =
  | TextQuestion
  | NumberQuestion
  | DateQuestion
  | DateMonthQuestion
  | PhotoActionQuestion
  | ChoiceQuestion
  | MultiChoiceQuestion
  | CustomFieldUpdateQuestion
  | LinkingQuestion;

export function isRequiredModelQuestion(
  question: any
): question is RequiredModelQuestion {
  return question.hasOwnProperty("isRequired");
}

export enum ValidationError {
  REQUIRED_QUESTION = "requiredQuestion",
  REQUIRED_NOTE = "requiredNote",
  REQUIRED_PHOTOS = "requiredPhotos",
  NEW_VALUE_MUST_BE_DIFFERENT_THAN_CURRENT = "newValueMustBeDifferentThanCurrent",
  NEW_VALUE_CANNOT_BE_EMPTY = "newValueCannotBeEmpty",
}

export interface QuestionResponseValidationError {
  questionId: string;
  errors: ValidationError[];
}

const _allRegularQuestionTypes: Record<RegularQuestion["questionType"], any> = {
  text: "",
  number: "",
  date: "",
  "date-month": "",
  choice: "",
  "multi-choice": "",
  "photo-action": "",
};

const _allQuestionTypes: Record<TemplateQuestion["questionType"], any> = {
  ..._allRegularQuestionTypes,
  "custom-field-update": "",
  linking: "",
};

export const allQuestionTypes: TemplateQuestion["questionType"][] = Object.keys(
  _allQuestionTypes
) as TemplateQuestion["questionType"][];

export const allRegularQuestionTypes: RegularQuestion["questionType"][] =
  Object.keys(_allRegularQuestionTypes) as RegularQuestion["questionType"][];
