import { Position } from "@inspecto/common";

import { ChargedEmployee } from "./Charges";
import { NotNoneCustomVehicleFieldValueStatus } from "./CustomVehicleFieldValue";
import { RetrieveCreateUpdateEmployee, SimpleEmployee } from "./Employee";
import { SignatureType } from "./Template";
import { VehicleObject } from "./VehicleObject";

export interface Protocol {
  id: string;
  label: string;
  createdAt: string;
  createdAtFormatted: string;
  status: NotNoneCustomVehicleFieldValueStatus;
}

export interface ListProtocol {
  id: string;
  protocolObject: Protocol;
  createdByObject: RetrieveCreateUpdateEmployee;
  vehicleObject: VehicleObject;
  last_edited: string | null;
}

interface ResponseComment {
  id: string;
  author: SimpleEmployee;
  date: string;
  text: string;
}

export interface ResponseCommon {
  id: string;
  label: string;
  status: NotNoneCustomVehicleFieldValueStatus;
  statusOverriddenBy: SimpleEmployee | null;
  statusOverriddenAt: string | null;
  order: number;
  comment: null | ResponseComment;
  chargedEmployee: null | ChargedEmployee;
  customVehicleFieldId: string | null;
  historicalCustomVehicleFieldValueId: string | null;
  isRequired: boolean | null;
}

export interface TextResponse extends ResponseCommon {
  type: "text";
  value: string;
}

export interface NumberResponse extends ResponseCommon {
  type: "number";
  value: string;
}

export interface DateResponse extends ResponseCommon {
  type: "date";
  value: string;
}

export interface DateMonthResponse extends ResponseCommon {
  type: "date-month";
  value: string;
}

export interface ChoiceResponseChoice {
  id: string;
  label: string;
  wasSelected: boolean;
}

export interface ChoiceResponse extends ResponseCommon {
  type: "choice";
  choices: ChoiceResponseChoice[];
  note: string;
  photos: { photo: string }[];
  isMultichoice: boolean;
}

export interface PhotoActionResponse extends ResponseCommon {
  type: "photo-action";
  value: string;
}

interface DamagesResponseDamageCommon {
  id: string;
  isNewAddition: boolean;
  damage: {
    id: string;
    label: string;
    photo: string | null;
    chargedEmployeeObject: ChargedEmployee | null;
  };
}

export interface NewDamagesResponseDamage extends DamagesResponseDamageCommon {
  isNewAddition: true;
}

export interface OldDamagesResponseDamage extends DamagesResponseDamageCommon {
  isNewAddition: false;
}

export type DamagesResponseDamage =
  | NewDamagesResponseDamage
  | OldDamagesResponseDamage;

export interface DamagesResponse extends ResponseCommon {
  type: "damages";
  newDamages: NewDamagesResponseDamage[];
  oldDamages: OldDamagesResponseDamage[];
}

export interface LinkingResponse extends ResponseCommon {
  type: "linking";
  linkValue: {
    id: string;
    leftVehicle: string;
    rightVehicle: string;
    leftVehiclePlateNumber: string;
    rightVehiclePlateNumber: string;
  };
}

export type Response =
  | TextResponse
  | NumberResponse
  | DateResponse
  | DateMonthResponse
  | ChoiceResponse
  | PhotoActionResponse
  | DamagesResponse
  | LinkingResponse;

const _allResponseTypes: Record<Response["type"], null> = {
  choice: null,
  "date-month": null,
  date: null,
  damages: null,
  number: null,
  text: null,
  "photo-action": null,
  linking: null,
};
export const allResponseTypes = Object.keys(
  _allResponseTypes
) as Response["type"][];

export function isChoiceResponse(
  response: Response
): response is ChoiceResponse {
  return response.type === "choice";
}

export function isTextResponse(response: Response): response is TextResponse {
  return response.type === "text";
}

export function isNumberResponse(
  response: Response
): response is NumberResponse {
  return response.type === "number";
}

export function isDateResponse(response: Response): response is DateResponse {
  return response.type === "date";
}

export function isDateMonthResponse(
  response: Response
): response is DateMonthResponse {
  return response.type === "date-month";
}

export function isPhotoActionResponse(
  response: Response
): response is PhotoActionResponse {
  return response.type === "photo-action";
}

export function isDamagesResponse(
  response: Response
): response is DamagesResponse {
  return response.type === "damages";
}

export function isLinkingResponse(
  response: Response
): response is LinkingResponse {
  return response.type === "linking";
}

export interface SignatureCommon {
  id: string;
  label: string;
  order: number;
  date: string;
}

export interface SignatureOnlySignature extends SignatureCommon {
  type: SignatureType.SIGNATURE_ONLY;
  signer: null;
  signerRoles: [];
  signature: null | string;
}

export interface SignerOnlySignature extends SignatureCommon {
  type: SignatureType.SIGNER_ONLY;
  signerRoles: string[];
  signer: null | {
    firstName: string;
    lastName: string;
  };
  signature: null;
}

export interface SignatureAndSignerSignature extends SignatureCommon {
  type: SignatureType.SIGNATURE_AND_SIGNER;
  signerRoles: string[];
  signer: null | {
    firstName: string;
    lastName: string;
  };
  signature: null | string;
}

export type RetrieveProtocolSignature =
  | SignatureOnlySignature
  | SignerOnlySignature
  | SignatureAndSignerSignature;

export enum EmailType {
  ACCOUNT_CREATED = "account_created",
  FORGOT_PASSWORD = "forgot_password",
  NEW_DAMAGES_CREATED = "new_damages_created",
  NEW_PROTOCOL_CREATED = "new_protocol_created",
  PDF_PROTOCOL = "pdf_protocol",
}

export enum EmailLogStatus {
  SUCCESSFULLY_SENT = "successfully_sent",
  FAILED_TO_SEND = "failed_to_send",
}

export enum EmailLogTriggeringSource {
  PUBLIC_API = "public_api",
  FORGOT_PASSWORD_FORM = "forgot_password_form",
  CSV_IMPORT = "csv_import",
}

export interface RetrieveProtocolEmailLog {
  createdAt: string;
  ccEmails: string[];
  emailData: object;
  emailSubject: string;
  emailType: EmailType.PDF_PROTOCOL;
  recipientEmail: string;
  sendingError: string | null;
  status: EmailLogStatus;
  triggeredByObject: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface RetrieveFilledOutProtocol {
  protocolObject: Protocol;
  createdByObject: RetrieveCreateUpdateEmployee;
  responseObjects: Response[];
  signatureObjects: RetrieveProtocolSignature[];
  vehicleObject: VehicleObject;
  createdInPositionObject: Position | null;
  emailLogs: RetrieveProtocolEmailLog[];
}
