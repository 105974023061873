import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

import { myTheme } from "src/theme";

interface LinkedObjectLinkProps extends LinkProps {
  $rightMargin?: boolean;
}

export const LinkedObjectLink = styled(Link)<LinkedObjectLinkProps>`
  ${({ $rightMargin }) => $rightMargin && "margin-right: 8px;"}
  font-weight: 600;
  border: 1px dashed ${myTheme.colors.whitePanelBorderColor};
  border-radius: 10px;
  padding: 1px 8px;
  display: inline-block;
  white-space: nowrap;
`;
