import { NotNoneCustomVehicleFieldValueStatus } from "src/backOffice/models";
import { TranslatedField } from "src/locales";

import {
  ChoiceResponse,
  DateFieldValue,
  DateMonthFieldValue,
  NumberFieldValue,
  PhotoActionFieldValue,
} from "..";
import { ChoiceFieldValue } from "./ChoiceFieldComponent";

export enum CommonRequirementType {
  Required = "required",
  Optional = "optional",
  None = "none",
}

export const commonRequirementTypePriorities: CommonRequirementType[] = [
  CommonRequirementType.None,
  CommonRequirementType.Optional,
  CommonRequirementType.Required,
];

export type TextFieldValue = string;

export type LinkingResponse = { value: string; label: string };

export type FieldValue =
  | TextFieldValue
  | DateFieldValue
  | NumberFieldValue
  | ChoiceFieldValue
  | PhotoActionFieldValue
  | LinkingResponse;

export type TextFieldType = "text";
export type NumberFieldType = "number";
export type DateFieldType = "date";
export type DateMonthFieldType = "date-month";
export type ChoiceFieldType = "choice";
export type PhotoActionFieldType = "photo-action";

export type FieldType =
  | TextFieldType
  | NumberFieldType
  | DateFieldType
  | DateMonthFieldType
  | ChoiceFieldType
  | PhotoActionFieldType;

export const fieldTypes: FieldType[] = [
  "text",
  "number",
  "date",
  "date-month",
  "choice",
  "photo-action",
];

export interface TextField {
  type: TextFieldType;
  value: TextFieldValue;
}

export interface NumberField {
  type: NumberFieldType;
  value: NumberFieldValue;
}

export interface DateField {
  type: DateFieldType;
  value: DateFieldValue;
  suggestedValues: number[];
}

export interface DateMonthField {
  type: DateMonthFieldType;
  value: DateMonthFieldValue;
}

export interface PhotoActionField {
  type: PhotoActionFieldType;
  value: PhotoActionFieldValue;
}

export interface CommonChoiceProperties {
  noteRequirement: CommonRequirementType;
  photosRequirement: CommonRequirementType;
}

interface _BaseChoiceFieldChoice extends CommonChoiceProperties {
  readonly label: string;
  labelTranslations: TranslatedField;
  setsStatus: NotNoneCustomVehicleFieldValueStatus;
}

export interface CreateChoiceFieldChoice extends _BaseChoiceFieldChoice {
  readonly frontendId: string;
  readonly id: null;
}

export interface UpdateChoiceFieldChoice extends _BaseChoiceFieldChoice {
  readonly frontendId: string;
  readonly id: string;
}

export interface RetrieveChoiceFieldChoice extends _BaseChoiceFieldChoice {
  readonly id: string;
}

export interface ChoiceField {
  type: ChoiceFieldType;
  value: ChoiceFieldValue;
  choices: RetrieveChoiceFieldChoice[];
}

export type Field =
  | TextField
  | NumberField
  | DateMonthField
  | DateField
  | ChoiceField
  | PhotoActionField;

export interface FieldValueByType {
  text: TextFieldValue;
  number: NumberFieldValue;
  date: DateFieldValue;
  "date-month": DateMonthFieldValue;
  choice: ChoiceFieldValue;
  "photo-action": PhotoActionFieldValue;
}

export interface FieldValueForVerificationByType
  extends Omit<FieldValueByType, "choice"> {
  choice: ChoiceResponse;
}

export const emptyFieldValueByType: FieldValueByType = {
  text: "",
  date: null,
  "date-month": null,
  number: null,
  choice: null,
  "photo-action": null,
};

// TODO: FIGURE OUT PROPER NAMING AND CONSIDER REMOVING THAT OR THE ABOVE emptyFieldValueByType
export const emptyFieldValueForVerificationByType: FieldValueForVerificationByType =
  {
    ...emptyFieldValueByType,
    choice: {
      selectedChoiceIds: [],
      note: "",
      photos: [],
    },
  };
