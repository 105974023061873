import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  vehicleGroupsOptions: { label: string; value: string }[];
}

export function VehicleGroupsFormItem(props: Props) {
  const { t } = useTranslation("backoffice");

  return (
    <Form.Item label={t("vehicleGroups")} name="vehicleGroups">
      <Select
        allowClear
        mode="multiple"
        style={{ maxWidth: 400 }}
        options={props.vehicleGroupsOptions}
        placeholder={t("reportsView.filters.allVehicleGroupsLabel")}
      />
    </Form.Item>
  );
}
