import { CustomVehicleFieldType } from "src/company";
import {
  ChoiceResponse,
  DateFieldValue,
  DateMonthFieldValue,
  LinkingResponse,
  NumberFieldValue,
  PhotoActionFieldValue,
  TextFieldValue,
} from "src/components";
import { StorageUploadedImage } from "src/storage";

export interface Signature<TSignature = File> {
  signature: TSignature | null;
  signer: { label: string; value: string } | null;
}

export type Signatures = Record<string, Signature>;

export interface Damage {
  label: string;
  photo: StorageUploadedImage | null;
}

export type NumberResponse = NumberFieldValue;

export type FieldVerification = "correct" | "incorrect" | null;

type NoNewDamagesConfirmed = "NO_NEW_DAMAGES_CONFIRMED";

type DamagesResponse = Damage[] | NoNewDamagesConfirmed;

export function isDamagesResponsesDamages(
  responses: DamagesResponse | undefined
): responses is Damage[] {
  return Array.isArray(responses);
}

export interface Responses {
  textResponses: Record<string, TextFieldValue>;
  numberResponses: Record<string, NumberResponse>;
  dateResponses: Record<string, DateFieldValue>;
  dateMonthResponses: Record<string, DateMonthFieldValue>;
  choiceResponses: Record<string, ChoiceResponse>;
  photoActionResponses: Record<string, PhotoActionFieldValue>;
  damagesResponses: Record<string, DamagesResponse>;
  customFieldVerificationResponses: Record<string, FieldVerification>;
  linkingResponses: Record<string, LinkingResponse>;
}

type Response =
  | TextFieldValue
  | NumberResponse
  | DateFieldValue
  | ChoiceResponse
  | PhotoActionFieldValue
  | LinkingResponse;

export function isChoiceResponse(
  response: Response
): response is ChoiceResponse {
  return (
    typeof response === "object" &&
    response != null &&
    response.hasOwnProperty("selectedChoiceIds")
  );
}

export const responsesKeyByCustomFieldType: Record<
  CustomVehicleFieldType,
  keyof Pick<
    Responses,
    | "textResponses"
    | "numberResponses"
    | "dateResponses"
    | "dateMonthResponses"
    | "choiceResponses"
    | "photoActionResponses"
  >
> = {
  text: "textResponses",
  number: "numberResponses",
  date: "dateResponses",
  "date-month": "dateMonthResponses",
  choice: "choiceResponses",
  "photo-action": "photoActionResponses",
  damages: "dateResponses",
};
