import { fromLocalStorage } from "./utils";

type MigrationFunction = () => Promise<void>;

export const localStorageMigrations: MigrationFunction[] = [
  textNumberAndDateResponsesToCustomFieldVerificationResponses,
  linkingResponses,
];

async function textNumberAndDateResponsesToCustomFieldVerificationResponses() {
  const textResponses = fromLocalStorage("proto_filler__textResponses");
  const numberResponses = fromLocalStorage("proto_filler__numberResponses");
  const dateResponses = fromLocalStorage("proto_filler__dateResponses");

  const allResponses = {
    ...textResponses,
    ...numberResponses,
    ...dateResponses,
  };

  const customFieldVerificationResponses = Object.entries(allResponses).reduce(
    (acc, [key, _]) => ({
      [key]: "incorrect",
      ...acc,
    }),
    {}
  );

  localStorage.setItem(
    "proto_filler__customFieldVerificationResponses",
    JSON.stringify(customFieldVerificationResponses)
  );
}

async function linkingResponses() {
  localStorage.setItem("proto_filler__linkingResponses", "{}");
}
