import {
  CustomVehicleFieldValueStatus,
  ListCustomVehicleField,
  Response,
  RetrieveVehicleCustomVehicleField,
} from "./models";

const bitMaskByStatus: Record<CustomVehicleFieldValueStatus, number> = {
  none: 0x0001,
  ok: 0x0010,
  warning: 0x0100,
  not_ok: 0x1000,
};

function getStatusByBits(bits: number): CustomVehicleFieldValueStatus {
  if (bits & bitMaskByStatus["not_ok"]) {
    return "not_ok";
  } else if (bits & bitMaskByStatus["warning"]) {
    return "warning";
  } else if (bits & bitMaskByStatus["ok"]) {
    return "ok";
  }
  return "none";
}

interface FieldsWithNoStatusExist {
  name: "fields-with-no-status-exist";
  count: number;
}

export interface CustomVehicleFieldsStatusAndInfo {
  status: CustomVehicleFieldValueStatus;
  info: FieldsWithNoStatusExist | null;
}

export function getResultingVehicleFieldsStatusAndInfo(
  statuses: CustomVehicleFieldValueStatus[]
): CustomVehicleFieldsStatusAndInfo {
  const resultingStatusBits: number = statuses
    .map((status) => status)
    .reduce(
      (previousValue, currentValue) =>
        previousValue | bitMaskByStatus[currentValue],
      bitMaskByStatus["none"]
    );
  const resultingStatus = getStatusByBits(resultingStatusBits);
  return {
    status: resultingStatus,
    info:
      statuses.length && statuses.includes("none") && resultingStatus === "ok"
        ? {
            name: "fields-with-no-status-exist",
            count: statuses.filter((status) => status === "none").length,
          }
        : null,
  };
}

export function normalizeStringForSearch(input: string): string {
  return input.toLowerCase().replaceAll(" ", "");
}

export function filterCustomVehicleFields(
  customVehicleFields: ListCustomVehicleField[],
  customVehicleFieldValues: Record<string, RetrieveVehicleCustomVehicleField>,
  filters: {
    searchPhrase: string;
    displayOnlyNotOk: boolean;
  }
): ListCustomVehicleField[] {
  return customVehicleFields
    .filter((customVehicleField) => {
      const customVehicleFieldValue =
        customVehicleFieldValues[customVehicleField.id];

      if (customVehicleFieldValue && filters.displayOnlyNotOk) {
        return ["warning", "not_ok"].includes(
          customVehicleFieldValue.valueObject.status
        );
      } else {
        return true;
      }
    })
    .filter((customVehicleField) =>
      normalizeStringForSearch(customVehicleField.label).includes(
        normalizeStringForSearch(filters.searchPhrase)
      )
    );
}

export function isElementVisibleInsideContainer(
  containerElement: HTMLElement,
  element: HTMLElement
) {
  const containerRect = containerElement.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.bottom <= containerRect.bottom
  );
}

export function getResponseTextRepresentation(response: Response) {
  switch (response.type) {
    case "text":
    case "number":
    case "date":
    case "date-month":
      return response.value;
    case "choice":
      return response.choices
        .filter((choice) => choice.wasSelected)
        .map((choice) => choice.label)
        .join(", ");
    default:
      return null;
  }
}
